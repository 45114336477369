/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@font-face {
    font-family: "Comfortaa";
    src: url("assets/fonts/Comfortaa-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "Comfortaa";
    src: url("assets/fonts/Comfortaa-Bold.ttf") format("opentype");
    font-weight: bold;
}

@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat-Bold.ttf") format("opentype");
    font-weight: bold;
}

@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat-SemiBold.ttf") format("opentype");
    font-weight: 600;
}

* {
    font-family: "Montserrat" , sans-serif !important;
}

h5, h6, .title {
    font-family: "Comfortaa" !important;
    font-weight: bold;
}

h6, .primary-icon {
    color: var(--primary-color);
}

.sub-title {
    font-weight: bold;
}

.light-text {
    font-size: 12px;
}

.icon {
    padding-left: 5px;
    padding-right: 5px;
}

.list {
    padding-bottom: 10px;
}

.card-flex {
    height: 100%;
}

.card-body-flex {
    display: flex;
    align-items: center;
    flex-direction: column;
}

body{
    --primary-color: #1975BB;
    --info-color: #1099D5;
    --success-color: #33D995;
    --secondary-color: #707070;
    --light-color: #CCE1F0;
    --background-color: #F9FBFC;
    --border-color: #00000029;
    --dark-color: #343A40;
    --half-dark-color: #484848;
    --warning-color: #FBBC28;
    --danger-color: #E94A4A;
    background: var(--background-color);
}

.outline-primary-btn {
    border: 1px solid var(--primary-color);
    background-color: white;
    color: var(--primary-color);
    font-family: 'Montserrat Regular';
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
}

.outline-primary-btn:hover {
    color: var(--primary-color) !important;
}

.primary-color {
    color: var(--primary-color);
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;

    .card-header {
        border-radius: var(--bs-card-inner-border-radius);
    }
}

.subtitle {
    font-size: 14px;
    color: var(--success-color);
}

.upper {
    text-transform: uppercase;
}
